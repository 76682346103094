.Home{
   display: flex;
   justify-content: center; 
   align-items: center;
   height: 100vh;
   flex-direction: column;
}
.editbtn{
    margin-left: 10px;
}
input[type=text] {
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  background-color: var(--Primary);
  color: white;
  border: none;
  border-radius: 26px;

}
input:focus{
    outline: none;
}

 input[type="text"][readonly] {
    opacity: 50%;
  }

  .repoText{
    position:absolute;
    bottom: 30%;
    text-decoration: underline;
  }
  @media only screen and (max-width: 600px) {
  .inputContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  }
    .editbtn{
      margin-top: 10%;
    }
}