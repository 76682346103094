@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}



html,
body {

  scroll-behavior: smooth;
  display: block!important;
  position: relative;
  font-family: 'Roboto', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  overflow-x: hidden;
background-color: #FF3CAC;
background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);


  z-index: 1;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  color: white;
  width: 100%;
}
::-webkit-scrollbar {
  width: 10px;
  position: relative;
  z-index: 100;

}

body::-webkit-scrollbar {
  /* Customize the scrollbar width and color */
  width: 8px;
  background-color: #eeeeee;
    position: relative;
  z-index: 100;
  

}

body::-webkit-scrollbar-thumb {
  /* Customize the scrollbar thumb */
  background-color: #333333;
  border-radius: 4px;
  z-index: 1000;
}

body::-webkit-scrollbar-thumb:hover {
  /* Customize the scrollbar thumb on hover */
  background-color: #555555;

}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}
button {
  padding: 0;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  font-weight: inherit;
  line-height: 1.1;
  margin-top: 0;
  font-size: inherit;
}
.Title1 {
  font-size: var(--Title1);
  font-weight: 700;
}
.Title2 {
  font-size: var(--Title2);
  font-weight: 700;
}
.Title3 {
  font-size: var(--Title3);
  font-weight: 700;
}
@media only screen and (max-width: 940px) {
.Title1 {
    font-size: var(--TabletTitle1);
    font-weight: 700;
  }
  .Title2 {
    font-size: var(--TabletTitle2);
    font-weight: 700;
  }
  .Title3 {
    font-size: var(--TabletTitle3);
    font-weight: 700;
  }
 p {
  font-size: var(--TabletNormal);
  font-weight: 300;
}
}
@media only screen and (max-width: 600px) {
  .Title1 {
    font-size: var(--PhoneTitle1);
    font-weight: 700;
  }
  .Title2 {
    font-size: var(--PhoneTitle2);
    font-weight: 700;
  }
  .Title3 {
    font-size: var(--PhoneTitle3);
    font-weight: 700;
  }
  p {
  font-size: var(--PhoneNormal);
  font-weight: 300;
}
}
p {
  font-size: var(--Normal);
  font-weight: 300;
}
a,
a:visited,
a:active,
a:hover
li {
  text-decoration: none;
  color: inherit;
  list-style-type: none;
}
.contained-l {
  padding-left: 7.3rem;
}
.contained-r {
  padding-right: 7.3rem;
}
.contained {
  padding-left: 7.3rem;
  padding-right: 7.3rem;
}
a:hover{
  color:inherit;
}
@media only screen and (max-width: 600px) {
  p {
    font-size: var(--PhoneNormal);
    font-weight: 300;
  }
  .Title {
    font-size: large;
  }
  .contained-l {
    padding-left: 1rem;
  }
  .contained-r {
    padding-right: 1rem;
  }
  .contained {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.Title {
  font-size: x-large;
}
#root,
#__next {
  isolation: isolate;
}
.hidden {
  opacity: 0;
}
.show {
  opacity: 1;
}

button {
  width: 9.5rem;
  padding: 2px 2px;
  font-size: 15px;
  background-color: var(--Primary);
  border: solid var(--Primary) 3px;
  color: var(--SecBackground);
  border-radius: 25px;
  transition: all 300ms ease-in-out;

} //default button design
button:hover {
  background-color: white;
  border-color: var(--Primary);
  color:var(--Primary);
  scale:1.1;
} // hover color effect
.btnBlue
{
    background-color: var(--Dark);
    color: var(--Background);
     border: solid var(--Background) 3px;
}
.btnBlue:hover
{
   background-color: var(--HoverSecondary);
}
select {
  background-color: rgba(240, 248, 255, 0);
  border-color: var(--Orange);
  padding: 0.5rem;
  width: 12rem;
  border-radius: 3rem;
  color: var(--Orange);
}
