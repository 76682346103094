.drop-container{
    display: flex;
    width: 30vw;
    height: 30px;
    flex-direction: column;
    justify-content: center;
    padding-right: 5%;
}
.Edit{
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.dropdown{
    margin: 10px 10px;
}
button:disabled {

  opacity: 30%;
}
button:disabled:hover {

  scale: 1;
  background-color: var(--Primary);
  color: white;
}
.infoContainer{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}
.infoContainer span{
    padding:5px 0;
}
.savebtn{
    margin-top: 30px;
}

.infoContainer{
    width: 30vw;
    padding-left: 5%;
}
.debugtab{
    text-align: center;
    padding-bottom: 30px;
}

.answer{
    color:aqua;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .Edit {flex-direction: column;
overflow: scroll;
padding-top: 20%;}
  .drop-container{width: 90vw;
padding-right: 0;
height: fit-content;
scale: 0.7;}
.infoContainer {
    width: 90vw;
    scale: 0.7;
    padding-left: 0;
}
}