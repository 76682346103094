.NavBar{
    width: 100vw;
    height: fit-content;
    background-color: white;
    position: absolute;
    top: 0;
    padding: 1rem 2rem;
    display: flex;
    justify-content:center;
    align-items: center;
    user-select: none;
}
.title-nav{
    color: #6853de;
    font-weight: 600;
    font-size: larger;

}
.Logo{
    height: 5vh;
    transform: translateX(-24rem);
}
@media only screen and (max-width: 600px) {
    .NavBar{
        padding-left: 1rem;
    padding-right: 1rem;
    }
  .title-nav{
    font-size: 16px;
    scale: 0.8;
  }
  .Logo {
    height: 5vh;
    transform: translateX(0rem);
    
}
}