:root {
--Title1: 2rem;
--Title2: 3rem;
--Title3: 2.1rem;
--Title4: 1.3rem;
--Normal: 1rem;

--TabletTitle1:2rem;
--TabletTitle2:2rem;
--TabletTitle3:1.8rem;
--TabletNormal:1.2rem;

--PhoneTitle1: 2rem;
--PhoneTitle2: 1.6rem;
--PhoneTitle3: 1.4rem;
--PhoneNormal: 1.1rem;
  
}
