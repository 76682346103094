:root {
  --Primary: #3eaffe;
  --Secondary: #6e55e3;
  --tint: #99dafb;
  --HoverPrimary:#6146fb;
  --HoverSecondary:#1b1b3e;
  --Background: #FAFAFA;
  --SecBackground: #F3EED9;
  --Dark: #21214D;
}
